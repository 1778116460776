import { Injectable } from '@angular/core';
import { Inject,  PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import WaveSurfer from 'wavesurfer.js'
import { LastRelease, Song } from '../models/last-release.model';
import { HttpClient } from '@angular/common/http';


@Injectable({
    providedIn: 'root'
})


export class PlayerService {
    public indexSong: number = 0;
    public volumeValue: number = 0.60;
    public loopActive: boolean = true;
    public displayNone: boolean = false;
    public currentTime: string = '0:00';
    public totalTime: string = '0:00';
    public currenTime: number = 0;
    public currentTimeSlider: number = 0;
    public isAudioLoaded: boolean = false;
    private wavesurfer!: WaveSurfer;
    private displayNoneSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private isAudioLoadedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public lastRelease!: LastRelease;
    public songSubject: BehaviorSubject<Song> = new BehaviorSubject<Song>({} as Song);
    public songsSubject: BehaviorSubject<Song[]> = new BehaviorSubject<Song[]>([] as Song[]);
    public lastReleaseSubject: BehaviorSubject<LastRelease> = new BehaviorSubject<LastRelease>({} as LastRelease);
    songs!: Song[];

    constructor(@Inject(
        PLATFORM_ID) private platformId: Object,
        private http: HttpClient
    ) {

    }
    setSongSubject() {
        this.songSubject.next(this.songs[this.indexSong]);
    }
    setSongsSubject() {
        this.songsSubject.next(this.songs);
    }
    setLastReleaseSubject() {
        this.lastReleaseSubject.next(this.lastRelease);
    }

    setSongs(songs: Song[]) {
        this.songs = songs;
        this.setSongsSubject();
    }
    setLastRelease(lastRelease: LastRelease): void {
        this.lastRelease = lastRelease;
        this.setLastReleaseSubject();
    }

    getLastRelease(): LastRelease {
        return this.lastRelease;
    }
    getLastReleasesJsonData(): Observable<any> {
        const url = `/assets/content/last_release.json?nocache=${new Date().getTime()}`;
        return this.http.get<any>(url);
    }

    getCurrectSong() {
        return this.songs[this.indexSong]
    }
    setIndexSong(index: number) {
        this.indexSong = index;
        this.updateSongSubject(this.songs[index]);
    }
    updateDisplayNone(value: boolean): void {
        this.displayNoneSubject.next(value);
    }
    getDisplayNoneObservable(): Observable<boolean> {
        return this.displayNoneSubject.asObservable();
    }
    updateSongSubject(song: Song) {
        this.songSubject.next(song);
    }
    getSongObservable(): Observable<Song> {
        return this.songSubject.asObservable();
    }
    getSongsObservable(): Observable<Song[]> {
        return this.songsSubject.asObservable();
    }
    getLastReleaseObservable(): Observable<LastRelease> {
        return this.lastReleaseSubject.asObservable();
    }
    updateIsAudioLoadedSubject(loaded: boolean) {
        this.isAudioLoadedSubject.next(loaded);
    }
    getisAudioLoadedObservable(): Observable<boolean> {
        return this.isAudioLoadedSubject.asObservable();
    }
    getWavesurfer(): WaveSurfer {
        return this.wavesurfer;
    }
    setEvents(index: number) {
        this.wavesurfer.on('ready', () => {
            this.updateIsAudioLoadedSubject(true);
            this.play();
            this.isAudioLoaded = true;
            this.displayNone = true;
            //this.updateDisplayNone(this.displayNone);
            this.wavesurfer?.setVolume(this.volumeValue);
            const totalMinutes = Math.floor(this.wavesurfer.getDuration() / 60);
            const totalSeconds = Math.floor(this.wavesurfer.getDuration() % 60);
            this.totalTime = `${totalMinutes}:${totalSeconds < 10 ? '0' : ''}${totalSeconds}`;
        });
        this.wavesurfer.on('timeupdate', () => {
            this.updateTime();
        });
        this.wavesurfer.on('finish', () => {
            if (this.loopActive) {
                if (index < (this.songs.length - 1)) {
                    this.preloadAudio(index + 1);
                } else {
                    this.preloadAudio(0);
                }
            } else {
                // this.setFinish();
            }

        });
    }
    preloadAudio(index: number): void {
        this.wavesurfer?.destroy();
        //this.wavesurfer?.empty();
        //this.wavesurfer?.unAll();        
        this.wavesurfer = new WaveSurfer({
            container: '.display__wrap__wave__waveform',
            waveColor: 'rgb(25, 41, 79)',
            progressColor: 'rgb(21, 101, 192)',
            barWidth: 2,
            //barRadius: 2,
            height: 30,
            normalize: true,
            autoplay: false,
            dragToSeek: true,
            sampleRate: 44100
            /*renderFunction: (channels, ctx) => {
            const { width, height } = ctx.canvas
            const scale = channels[0].length / width
            const step = 4
        
            ctx.translate(0, height / 2)
            ctx.strokeStyle = ctx.fillStyle
            ctx.beginPath()
        
            for (let i = 0; i < width; i += step * 2) {
              const index = Math.floor(i * scale)
              const value = Math.abs(channels[0][index])
              let x = i
              let y = (value * height) / (step * .5)
        
              ctx.moveTo(x, 0)
              ctx.lineTo(x, y)
              ctx.arc(x + step / 2, y, step / 2, Math.PI, 0, true)
              ctx.lineTo(x + step, 0)
        
              x = x + step
              y = -y
              ctx.moveTo(x, 0)
              ctx.lineTo(x, y)
              ctx.arc(x + step / 2, y, step / 2, Math.PI, 0, false)
              ctx.lineTo(x + step, 0)
            }
        
            ctx.stroke()
            ctx.closePath()
          }*/
        });
        this.indexSong = index;
        this.wavesurfer.load(this.songs[index].audio);
        this.updateSongSubject(this.songs[index]);
        this.updateIsAudioLoadedSubject(false);
        this.setEvents(index);




    }

    play() {
        this.wavesurfer.play();
    }

    pause() {
        this.wavesurfer.pause();
    }

    stop(): void {
        if (this.wavesurfer) {
            this.wavesurfer.pause();
            this.wavesurfer.setTime(0);
        }
    }
    getIsPlaying(): boolean {
        return this.wavesurfer?.isPlaying();
    }

    setTime(time: number) {
        this.wavesurfer.setTime(time);
    }
    setVolume(volume: number) {
        this.wavesurfer?.setVolume(volume);
        this.volumeValue = volume;
    }
    increaseVolume(): void {
        if (this.wavesurfer && this.wavesurfer.getVolume() <= 0.9) {
            this.wavesurfer.setVolume(this.wavesurfer.getVolume() + 0.1);
            this.volumeValue = this.wavesurfer.getVolume() + 0.1;
        } else {
            this.volumeValue = this.wavesurfer.getVolume() + 0.1;
        }
    }

    decreaseVolume(): void {
        if (this.wavesurfer && this.wavesurfer.getVolume() >= 0.1) {
            this.wavesurfer.setVolume(this.wavesurfer.getVolume() - 0.1);
            this.volumeValue = this.wavesurfer.getVolume() - 0.1;
        } else {
            this.volumeValue = this.wavesurfer.getVolume() - 0.1;
        }
    }

    toggleLoop(): void {
        if (this.wavesurfer) {
            this.loopActive = !this.loopActive;
        }
    }

    getLoop(): boolean {
        if (this.loopActive) {
            return true;
        } else {
            return false;
        }
    }

    updateTime() {
        const currentTime = this.wavesurfer.getCurrentTime();
        const currentMinutes = Math.floor(currentTime / 60);
        const currentSeconds = Math.floor(currentTime % 60);
        this.currentTime = `${currentMinutes}:${currentSeconds < 10 ? '0' : ''}${currentSeconds}`;

        const totalMinutes = Math.floor(this.wavesurfer.getDuration() / 60);
        const totalSeconds = Math.floor(this.wavesurfer.getDuration() % 60);
        this.totalTime = `${totalMinutes}:${totalSeconds < 10 ? '0' : ''}${totalSeconds}`;

        this.currentTimeSlider = (currentTime * 100) / this.wavesurfer.getDuration();

        if (this.currentTime == this.totalTime && !this.loopActive) {
            this.setFinish();
        }
    }

    setFinish() {
        this.wavesurfer.stop();
        this.currentTimeSlider = 0;
        this.currentTime = '0:00';
        if (!this.loopActive) {
        } else {
            this.wavesurfer.play();
        }
    }


}